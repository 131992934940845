export function formatMoney(
  cents: string | number,
  format?: string | null,
  symbol = '$',
) {
  const money_format = `${symbol}{{amount}}`;

  if (format && (format.includes('span') || format.includes('div'))) {
    const match: string[] | null = format?.match(
      '([^\\>^a-zA-Z0-9]?\\{\\{.*\\}\\})',
    );

    // eslint-disable-next-line no-param-reassign
    format = match?.length ? match[0] : format;
  }

  if (typeof cents === 'string') {
    // eslint-disable-next-line no-param-reassign
    cents = cents.replace('.', '');
  }

  let value = '';
  const placeholderRegex = /\{\{\s*(\w+)\s*\}\}/;
  const formatString = format || money_format;

  function defaultOption(opt: any, def: any) {
    return typeof opt === 'undefined' ? def : opt;
  }

  function formatWithDelimiters(
    number: any,
    precision: any,
    thousands?: any,
    decimal?: any,
  ) {
    // eslint-disable-next-line no-param-reassign
    precision = defaultOption(precision, 2);
    // eslint-disable-next-line no-param-reassign
    thousands = defaultOption(thousands, ',');
    // eslint-disable-next-line no-param-reassign
    decimal = defaultOption(decimal, '.');

    if (Number.isNaN(number) || number == null) {
      return 0;
    }

    // eslint-disable-next-line no-param-reassign
    number = (number / 100.0).toFixed(precision);

    const parts = number.split('.');
    const dollars = parts[0].replace(
      /(\d)(?=(\d\d\d)+(?!\d))/g,
      `$1${thousands}`,
    );
    // eslint-disable-next-line no-shadow
    const cents = parts[1] ? decimal + parts[1] : '';

    return dollars + cents;
  }

  switch (formatString.match(placeholderRegex)?.[1]) {
    case 'amount':
      value = formatWithDelimiters(cents, 2);
      break;
    case 'amount_no_decimals':
      value = formatWithDelimiters(cents, 0);
      break;
    case 'amount_with_comma_separator':
      value = formatWithDelimiters(cents, 2, '.', ',');
      break;
    case 'amount_no_decimals_with_comma_separator':
      value = formatWithDelimiters(cents, 0, '.', ',');
      break;
    case 'amount_with_apostrophe_separator':
      value = formatWithDelimiters(cents, 2, "'", '.');
      break;
    default:
      return value;
  }

  return formatString.replace(placeholderRegex, value);
}
