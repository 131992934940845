import { useMemo } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

export function useControlledFieldArray(name: string) {
  const { watch } = useFormContext();
  const { fields, ...actions } = useFieldArray({
    name,
    keyName: 'keyId',
  });

  const watchFieldArray = watch(name);
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  return useMemo(
    () => ({
      ...actions,
      fields: controlledFields,
    }),
    [actions, controlledFields],
  );
}
