import { useQuery } from 'react-query';

import { fetcher } from '@lib/fetcher';
import type { Offer } from '../../types';

export async function getOfferById(id: number) {
  const { offer } = await fetcher(`/offers/${id}`);
  return offer;
}

export function useOffer(id: number) {
  return useQuery<Offer>(['offer', { id }], () => getOfferById(id));
}
