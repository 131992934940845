import { useQuery } from 'react-query';

import { fetcher } from '@lib/fetcher';

interface OfferStatistics {
  [key: number]: {
    id: number;
    amount: string;
    orders: number;
    total_views: number;
  };
}

export async function getOffersStatistics() {
  const { stats } = await fetcher('/reports/offers');
  return stats;
}

export function useOffersStatistics() {
  return useQuery<OfferStatistics>(['reports', 'offers'], getOffersStatistics, {
    staleTime: Infinity,
  });
}
