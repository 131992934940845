import { useCallback } from 'react';
import { ValidationError, AnySchema } from 'yup';
import { useFormContext } from 'react-hook-form';

import { useConfirmModal } from '@components/ContextualSaveBar';
import { Offer } from '@pages/offer/types';

type UseSubmitFormProps = {
  onBack(): void;
  schema: AnySchema;
  adapter(data: Offer): Partial<Offer>;
  onSubmit(data: Offer): void;
};

export function useSubmitForm(props: UseSubmitFormProps) {
  const { schema, onBack, onSubmit, adapter } = props;
  const { handleSubmit, setError, reset, clearErrors } = useFormContext();
  const handleConfirmModal = useConfirmModal();

  const handleBack = useCallback(() => {
    handleConfirmModal(() => {
      reset();
      clearErrors();
      onBack();
    });
  }, [clearErrors, handleConfirmModal, onBack, reset]);

  const handleSave = useCallback(() => {
    clearErrors();
    handleSubmit(data => {
      try {
        schema.validateSync(adapter(data as Offer), { abortEarly: false });
        onSubmit(data as Offer);
      } catch (err) {
        if (err instanceof ValidationError) {
          err.inner.forEach(error => {
            setError(error?.path || 'custom', {
              type: 'manual',
              message: error?.message,
            });
          });
        }
      }
    })();
  }, [adapter, clearErrors, handleSubmit, onSubmit, schema, setError]);

  return { handleBack, handleSave };
}
