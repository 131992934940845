import { useMutation, useQueryClient } from 'react-query';

import { fetcher } from '@lib/fetcher';
import type { Offer } from '@pages/offer/types';
import { useToast } from '@hooks/useToast';

export async function deleteOffer(id: number) {
  const response = await fetcher(`/offers/${id}`, {
    method: 'DELETE',
  });
  return response;
}

export function useOfferDelete() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(deleteOffer, {
    onSuccess: async (_, variables) => {
      const previousValue = queryClient.getQueryData<Offer[]>('offers');
      const newOffers = previousValue?.filter(({ id }) => id !== variables);
      await queryClient.setQueryData(['offers'], newOffers);
      toast({ message: 'Offer deleted' });
    },
    onError: () => {
      toast({ isError: true, message: 'Offer not deleted' });
    },
  });
}
