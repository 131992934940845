import styled from 'styled-components';

export const Wrapper = styled.div<{
  $backgroundColor: string;
}>`
  border-radius: 0.8rem;
  box-shadow: 0 0 0.1rem 0 rgba(0 0 0 / 40%);
  background-color: ${p => p.$backgroundColor};
`;

export const Inner = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  padding: 5rem;
  width: 50%;
  min-height: 36rem;
`;

export const ImageWrapper = styled.div<{ $image: string }>`
  width: 50%;
  background: url('${p => p.$image}') no-repeat center/cover;
  border-radius: 0 0.8rem 0.8rem 0;
`;
