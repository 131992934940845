import { useRouter } from 'next/router';

import { Grid, GridItem } from '@styles/Global';
import { MediaCard } from '@components/MediaCard';
import { getFormattedDate } from '@pages/home/helpers';
import { useDuplicateOffer, useOfferUpdate } from '@pages/offer/hooks';
import { Offer, OfferStatus } from '@pages/offer/types';
import { useShopTimezone } from '@hooks/useStores/useShopTimezone';
import { OfferList } from './OfferList';

type OfferScheduledListProps = {
  items: Offer[];
};

export const OfferScheduledList = ({ items }: OfferScheduledListProps) => {
  const router = useRouter();
  const { data } = useShopTimezone();
  const { mutateAsync } = useOfferUpdate();
  const duplicateOffer = useDuplicateOffer();

  return (
    <Grid $lg={2} $xxl={2}>
      <OfferList
        title="Scheduled"
        items={items}
        renderItem={offer => (
          <GridItem key={offer.id}>
            <MediaCard
              {...offer}
              description={getFormattedDate(data)(
                offer.status,
                offer.activeStart,
              )}
              popoverActions={[
                {
                  content: 'Update',
                  async onAction() {
                    await router.push(
                      `/offers/[offerId]`,
                      `/offers/${offer.id}`,
                    );
                  },
                },
                {
                  content: 'Duplicate',
                  disabled: duplicateOffer.isLoading,
                  async onAction() {
                    await duplicateOffer.mutateAsync(offer.id);
                  },
                },
                {
                  content: 'Unpublish',
                  destructive: true,
                  async onAction() {
                    await mutateAsync({
                      id: offer.id,
                      status: OfferStatus.DRAFT,
                    });
                  },
                },
              ]}
            />
          </GridItem>
        )}
      />
    </Grid>
  );
};
