import { useMutation, useQueryClient } from 'react-query';

import { fetcher } from '@lib/fetcher';
import { useToast } from '@hooks/useToast';
import type { Offer } from '@pages/offer/types';

export async function duplicateOffer(id: number) {
  const { offer } = await fetcher(`/offers/${id}/copy`, {
    method: 'POST',
  });
  return offer;
}

export function useDuplicateOffer() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation((id: number) => duplicateOffer(id), {
    onSuccess: async data => {
      const offers = queryClient.getQueryData<Offer[]>(['offers']) || [];
      queryClient.setQueryData(['offers'], [...offers, data]);
      toast({ message: 'Offer duplicated' });
    },
    onError: () => {
      toast({ isError: true, message: 'Offer not duplicated' });
    },
  });
}
