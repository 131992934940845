import { useMutation, useQueryClient } from 'react-query';

import { fetcher } from '@lib/fetcher';
import { useToast } from '@hooks/useToast';
import type { Offer } from '../../types';

export async function updateOfferRules(
  offerData: Partial<Offer>,
): Promise<Offer> {
  const { id, ...data } = offerData;
  const { offer } = await fetcher(`/offers/${id}/rules`, {
    method: 'PUT',
    data: { offer: data },
  });
  return offer;
}

export function useOfferRulesUpdate() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(updateOfferRules, {
    onSuccess: async (data, variables) => {
      queryClient.setQueryData(['offer', { id: variables.id }], data);
      toast({ message: 'Offer updated' });
      await queryClient.invalidateQueries(['offers']);
    },
    onError: () => {
      toast({ message: 'Offer not update', isError: true });
    },
  });
}
