import { TextStyle } from '@shopify/polaris';
import { formatMoney } from '@helpers/formatMoney';

type OfferAnalyticProps = {
  amount?: string;
  orders?: number;
  total_views?: number;
  symbol?: string;
};

export const OfferAnalytic = (props: OfferAnalyticProps) => {
  const { amount, orders, total_views, symbol } = props;

  if (!orders && !total_views) return null;

  const conversion =
    orders && total_views ? ((orders / total_views) * 100).toFixed(2) : '0.00';

  return (
    <div style={{ display: 'flex', marginTop: '3rem' }}>
      <div style={{ marginRight: '4rem' }}>
        <TextStyle>Revenue</TextStyle>
        <br />
        <TextStyle variation="subdued">
          {formatMoney(amount || 0, null, symbol)}
        </TextStyle>
      </div>
      <div>
        <TextStyle>Conversion</TextStyle>
        <br />
        <TextStyle variation="subdued">{conversion}%</TextStyle>
      </div>
    </div>
  );
};
