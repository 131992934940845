import styled from 'styled-components';
import { LinkStyled } from '@styles/Global';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.1rem 0 rgba(0 0 0 / 40%);
`;

export const Link = styled(LinkStyled)`
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.16;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  cursor: pointer;
`;

export const Image = styled.div`
  position: relative;
  height: 40%;
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  background-color: #eeeeee;
  overflow: hidden;
`;

export const Content = styled.div<{ $order: number }>`
  height: 60%;
  border-top-left-radius: 0.8rem;
  border-top-right-radius: 0.8rem;
  padding: 4.4rem 2.9rem;
  background-color: ${p => (p.$order % 2 === 0 ? '#baceda' : '#c0c0c0')};
`;

export const Heading = styled.h3`
  margin: 0 0 2.6rem;
  font-weight: 600;
  font-size: 2.4rem;
  line-height: 1.16;
`;

export const Button = styled.button`
  border: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.6rem;
  color: #202223;
  background-color: transparent;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const DescriptionWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  border-radius: 0.8rem;
  padding: 4.4rem 3rem 3.4rem;
  width: 100%;
  height: 100%;
  background-color: #ffffff;

  p {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.6;

    & + p {
      margin-top: 1.6rem;
    }
  }
`;

export const DescriptionHeading = styled.h3`
  margin-bottom: 3rem;
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 1.15;
`;

export const DescriptionContent = styled.div`
  overflow-y: auto;
`;

export const DescriptionFooter = styled.div`
  margin-top: 3.2rem;
`;
