import type { Product } from '@components/ProductsPicker';
import type { Template } from '../../types';

export interface BaseVariant {
  id: number;
}

export interface BaseProduct {
  id: number;
  variants?: BaseVariant[];
}

export enum RecommendationType {
  PRODUCTS = 'products',
  COLLECTION = 'collection',
  TAG = 'tag',
  QUIZ_PRODUCTS = 'quiz-products',
}

export interface RecommendationProduct {
  id: number;
  order: number;
  customImages: string[];
  description: string;
  variants: BaseVariant[];
}

export interface ProductsRecommendation {
  type: RecommendationType.PRODUCTS;
  products: RecommendationProduct[];
}

export interface CollectionRecommendation {
  type: RecommendationType.COLLECTION;
  collectionId: number;
  collectionName: string;
  productLimit?: number;
}

export interface TagRecommendation {
  type: RecommendationType.TAG;
  tagName: string;
  productLimit?: number;
}

export interface QuizKitRecommendation {
  type: RecommendationType.QUIZ_PRODUCTS;
  quizId: number;
  quizName: string;
}

export type Recommendation =
  | CollectionRecommendation
  | ProductsRecommendation
  | TagRecommendation
  | QuizKitRecommendation;

export interface AnyExpression {
  type: 'any';
}

export enum Condition {
  AND = 'and',
  OR = 'or',
  EXCLUDE = 'exclude',
}

export interface ProductsExpression {
  type: 'products';
  products: BaseProduct[];
  condition: Condition;
}

export interface CollectionExpression {
  type: 'collection';
  collectionId: string;
  collectionName: string;
}

export interface TagExpression {
  type: 'tag';
  tagName: string;
}

export interface AmountExpression {
  type: 'amount';
  min?: number;
  max?: number;
}

export type Expression =
  | AnyExpression
  | ProductsExpression
  | CollectionExpression
  | TagExpression
  | AmountExpression;

export type ExpressionType = Expression['type'];

export enum DiscountValueType {
  PERCENTAGE = 'percentage',
  FIXED_AMOUNT = 'fixed_amount',
}

export interface DiscountEffect {
  type: 'discount';
  value: number;
  valueType: DiscountValueType;
  title: string;
}

export interface ShippingEffect {
  type: 'shipping';
  value: number;
  valueType: DiscountValueType;
  title: string;
}

export type Effect = DiscountEffect | ShippingEffect;

export type EffectType = Effect['type'];

export enum OfferStatus {
  ACTIVE = 'active',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
}

export interface Rule {
  id: number;
  offerId: number;
  expression: Expression;
  expressionProducts: Product[];
}

export interface OfferStyles {
  [key: string]: Record<string, string | number | never>;
}

export interface Offer {
  id: number;
  name: string;
  conditions: boolean;
  storeId: number;
  status: OfferStatus;
  priority: number;
  previewImage: string | null;
  rules: Rule[];
  rulesCondition: Condition;
  settings: Record<string, unknown>;
  styles: OfferStyles;
  templateId: number;
  template: Template;
  recommendation: Recommendation;
  recommendationProducts: Product[];
  effect: Effect;
  activeEnd: string | null;
  activeStart: string | null;
  createdAt: string;
  updatedAt: string;
}

export interface StoreImage {
  originalSrc: string;
  transformedSrc: string;
}
