import { useQuery } from 'react-query';

import { fetcher } from '@lib/fetcher';

interface TotalStatistics {
  revenue: number | null;
  views: number;
}

export async function getTotalStatistics() {
  const { stats } = await fetcher('/reports/total');
  return stats;
}

export function useTotalStatistics() {
  return useQuery<TotalStatistics>(['reports', 'total'], getTotalStatistics, {
    staleTime: Infinity,
  });
}
