import { useRouter } from 'next/router';

import { Grid, GridItem } from '@styles/Global';
import { MediaCard } from '@components/MediaCard';
import { getFormattedDate } from '@pages/home/helpers';
import { useStoreCurrency, useShopTimezone } from '@hooks/useStores';
import {
  useDuplicateOffer,
  useOfferDelete,
  useOffersStatistics,
} from '@pages/offer/hooks';
import type { Offer } from '@pages/offer/types';
import { OfferAnalytic } from '@pages/home/components/Offers/OfferAnalytic';
import { OfferList } from './OfferList';

type OfferDraftListProps = {
  items: Offer[];
};

export const OfferDraftList = ({ items }: OfferDraftListProps) => {
  const router = useRouter();
  const { data } = useShopTimezone();
  const { symbol } = useStoreCurrency();
  const { mutateAsync } = useOfferDelete();
  const { data: stats } = useOffersStatistics();
  const duplicateOffer = useDuplicateOffer();

  return (
    <Grid $lg={2} $xxl={2}>
      <OfferList
        title="Drafts"
        items={items}
        renderItem={offer => (
          <GridItem key={offer.id}>
            <MediaCard
              {...offer}
              description={getFormattedDate(data)(
                offer.status,
                offer.updatedAt,
              )}
              content={<OfferAnalytic {...stats?.[offer.id]} symbol={symbol} />}
              popoverActions={[
                {
                  content: 'Update',
                  async onAction() {
                    await router.push(
                      `/offers/[offerId]`,
                      `/offers/${offer.id}`,
                    );
                  },
                },
                {
                  content: 'Duplicate',
                  disabled: duplicateOffer.isLoading,
                  async onAction() {
                    await duplicateOffer.mutateAsync(offer.id);
                  },
                },
                {
                  content: 'Delete',
                  destructive: true,
                  async onAction() {
                    await mutateAsync(offer.id);
                  },
                },
              ]}
            />
          </GridItem>
        )}
      />
    </Grid>
  );
};
