import { useQuery, UseQueryOptions } from 'react-query';

import { fetcher } from '@lib/fetcher';
import { Offer } from '../../types';

export async function getOffers() {
  const { offers } = await fetcher('/offers');
  return offers;
}

export function useOffers(options?: UseQueryOptions<Offer[]>) {
  return useQuery<Offer[]>(['offers'], getOffers, {
    staleTime: 1000 * 60 * 5,
    ...options,
  });
}
