import { Layout, Page } from '@shopify/polaris';

import { PageHeader } from '@components/Header';
import { PageLoader } from '@components/Loader';

import { TipsList } from '@pages/home';
import {
  OfferDraftList,
  OfferLiveList,
  OfferScheduledList,
} from '@pages/home/components/Offers';
import { HomeHero } from '@pages/home/components/Hero';
import { HeroSkeleton, MediaCardListSkeleton } from '@components/skeletons';

import { useCheckSubscription } from '@hooks/useCheckSubscription';
import { filterOffers } from '@pages/home/helpers';
import { useOffers } from '@pages/offer/hooks/useOffers/useOffers';

import { tips } from '@content/tips';
import { PageContainer } from '@styles/Global';
import { OfferStatus } from '@pages/offer/types';

const IndexPage = () => {
  const status = useCheckSubscription();
  const { data: offers, isLoading: isOffersLoading } = useOffers();
  const isOffers = !!offers?.length;

  if (status) return <PageLoader />;

  return (
    <PageContainer>
      <Page fullWidth>
        <Layout>
          <PageHeader title="Offers" />
          <Layout.Section fullWidth>
            {!isOffersLoading ? (
              <HomeHero description="Start with our industry-leading template, then customize your offer to best fit your needs." />
            ) : (
              <HeroSkeleton />
            )}
          </Layout.Section>
          {isOffersLoading && <MediaCardListSkeleton />}
          {isOffers && !isOffersLoading && (
            <>
              <OfferLiveList items={filterOffers(OfferStatus.ACTIVE, offers)} />
              <OfferScheduledList
                items={filterOffers(OfferStatus.SCHEDULED, offers)}
              />
              <OfferDraftList items={filterOffers(OfferStatus.DRAFT, offers)} />
            </>
          )}
          <TipsList items={tips} />
        </Layout>
      </Page>
    </PageContainer>
  );
};

export default IndexPage;
