import { useState } from 'react';
import Image from 'next/image';
import { Button } from '@shopify/polaris';
import { ReturnMinor } from '@shopify/polaris-icons';

import * as Tip from './styles';

type TipCardProps = {
  title: string;
  image: string;
  description: string;
  order: number;
};

export const TipsCard = (props: TipCardProps) => {
  const { title, image, description, order } = props;
  const [showDescription, setShowDescription] = useState(false);

  const descriptionMarkup = showDescription ? (
    <Tip.DescriptionWrapper>
      <Tip.DescriptionHeading>{title}</Tip.DescriptionHeading>
      <Tip.DescriptionContent
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Tip.DescriptionFooter>
        <Button
          plain
          size="slim"
          icon={ReturnMinor}
          onClick={() => setShowDescription(state => !state)}
        />
      </Tip.DescriptionFooter>
    </Tip.DescriptionWrapper>
  ) : null;

  return (
    <Tip.Wrapper>
      <Tip.Body onClick={() => setShowDescription(state => !state)}>
        <Tip.Content $order={order}>
          <Tip.Heading>{title}</Tip.Heading>
          <Tip.Button>Read</Tip.Button>
        </Tip.Content>
        <Tip.Image>
          <Image src={image} alt="" layout="fill" />
        </Tip.Image>
      </Tip.Body>
      {descriptionMarkup}
    </Tip.Wrapper>
  );
};
