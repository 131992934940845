import { useRouter } from 'next/router';

import { Grid, GridItem } from '@styles/Global';
import { MediaCard } from '@components/MediaCard';
import { Offer, OfferStatus } from '@pages/offer/types';
import { useStoreCurrency, useShopTimezone } from '@hooks/useStores';
import {
  useOfferUpdate,
  useDuplicateOffer,
  useOffersStatistics,
} from '@pages/offer/hooks';

import { OfferList } from './OfferList';
import { OfferAnalytic } from './OfferAnalytic';
import { getFormattedDate } from '../../helpers';

type OfferLiveListProps = {
  items: Offer[];
};

export const OfferLiveList = ({ items }: OfferLiveListProps) => {
  const router = useRouter();
  const { data } = useShopTimezone();
  const { symbol } = useStoreCurrency();
  const { mutateAsync } = useOfferUpdate();
  const { data: stats } = useOffersStatistics();
  const duplicateOffer = useDuplicateOffer();

  return (
    <Grid $lg={2} $xxl={2}>
      <OfferList
        title="Live"
        items={items}
        renderItem={offer => (
          <GridItem key={offer.id}>
            <MediaCard
              {...offer}
              description={getFormattedDate(data)(
                offer.status,
                offer.updatedAt,
              )}
              content={<OfferAnalytic {...stats?.[offer.id]} symbol={symbol} />}
              popoverActions={[
                {
                  content: 'Update',
                  async onAction() {
                    await router.push(
                      `/offers/[offerId]`,
                      `/offers/${offer.id}`,
                    );
                  },
                },
                {
                  content: 'Analytics',
                  async onAction() {
                    await router.push(`/analytics?offerId=${offer.id}`);
                  },
                },
                {
                  content: 'Duplicate',
                  disabled: duplicateOffer.isLoading,
                  async onAction() {
                    await duplicateOffer.mutateAsync(offer.id);
                  },
                },
                {
                  content: 'Unpublish',
                  destructive: true,
                  async onAction() {
                    await mutateAsync({
                      id: offer.id,
                      status: OfferStatus.DRAFT,
                    });
                  },
                },
              ]}
            />
          </GridItem>
        )}
      />
    </Grid>
  );
};
