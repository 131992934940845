export function formatNumbers(num: number | undefined, fixed = 2) {
  if (!num) return 0;
  if (num < 100000) return new Intl.NumberFormat('en-US').format(num);

  const si = [
    { v: 1e3, s: 'K' },
    { v: 1e6, s: 'M' },
    { v: 1e9, s: 'B' },
    { v: 1e12, s: 'T' },
    { v: 1e15, s: 'P' },
    { v: 1e18, s: 'E' },
  ];

  let index;

  for (index = si.length - 1; index > 0; index -= 1) {
    if (num >= si[index].v) break;
  }

  const number = (num / si[index].v)
    .toFixed(fixed)
    .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1');

  return `${number}${si[index].s}`;
}
