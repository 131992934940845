import { format, utcToZonedTime } from 'date-fns-tz';
import * as R from 'ramda';

import type { Offer } from '@pages/offer/types';
import { OfferStatus } from '@pages/offer/types';
import { ShopTimezone } from '@hooks/useStores/useShopTimezone';

export function getUsername(username: string | null | undefined) {
  if (!username) return null;
  return username?.split(/\s/)[0];
}

const templates = {
  active: {
    format: 'MMM d hh:mmaaa',
    template: 'Live: {date} {timeZone}',
  },
  scheduled: {
    format: "MMM d 'at' hh:mmaaa",
    template: 'Scheduled for {date} {timeZone}',
  },
  draft: {
    format: "MMM d 'at' hh:mmaaa",
    template: 'Last updated: {date} {timeZone}',
  },
};

function formattedTemplate(template: string, date: string, timeZone = '') {
  return template.replace(/({date})\s({timeZone})/g, `${date} ${timeZone}`);
}

export function getFormattedDate(timeZone: ShopTimezone | undefined) {
  const options = { timeZone: timeZone?.ianaTimezone || '' };

  return (status: OfferStatus, date: string) => {
    const template = templates[status];
    const zonedDate = utcToZonedTime(
      new Date(date),
      timeZone?.ianaTimezone || '',
    );
    return formattedTemplate(
      template.template,
      format(zonedDate, template.format, options),
      timeZone?.timezoneAbbreviation,
    );
  };
}

export const filterOffers = (
  status: OfferStatus,
  items: Offer[] | undefined,
) => {
  if (!items?.length) return [];

  const filterByStatus = R.filter(R.whereEq({ status }));
  const sortByDate = R.sort(
    R.descend(({ updatedAt }: Offer) => new Date(updatedAt).getTime()),
  );

  return sortByDate(filterByStatus(items));
};
