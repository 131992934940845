import { Layout, TextStyle, DisplayText } from '@shopify/polaris';

import { Grid, GridItem } from '@styles/Global';
import { TipsCard } from './TipsCard';

type TipsListProps = {
  items: {
    title: string;
    url: string | null;
    image: string;
    description: string;
  }[];
};

export const TipsList = ({ items }: TipsListProps) => {
  if (!items.length) return null;

  return (
    <>
      <Layout.Section fullWidth>
        <DisplayText element="h2" size="small">
          <TextStyle variation="strong">Articles</TextStyle>
        </DisplayText>
      </Layout.Section>
      <Grid $sm={1} $md={2} $lg={3}>
        {items.map((tip, index) => (
          <GridItem key={index}>
            <TipsCard {...tip} order={index} />
          </GridItem>
        ))}
      </Grid>
    </>
  );
};
