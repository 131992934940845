import { useMutation, useQueryClient } from 'react-query';
import { useRouter } from 'next/router';

import { fetcher } from '@lib/fetcher';
import { useToast } from '@hooks/useToast';

export async function createOffer(id: number) {
  const { offer } = await fetcher('/offers', {
    method: 'POST',
    params: { templateId: id },
  });
  return offer;
}

export function useOfferCreate() {
  const router = useRouter();
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation((id: number) => createOffer(id), {
    onSuccess: async offer => {
      toast({ message: 'Offer created' });
      await router.push(`/offers/[offerId]`, `/offers/${offer.id}`);
      await queryClient.invalidateQueries(['offers']);
    },
    onError: () => {
      toast({ isError: true, message: 'Offer not created' });
    },
  });
}
