import { useMutation, useQueryClient } from 'react-query';

import { fetcher } from '@lib/fetcher';
import { useToast } from '@hooks/useToast';
import type { Offer } from '../../types';

export async function updateOffer(offerData: Partial<Offer>) {
  const { id, ...data } = offerData;
  const { offer } = await fetcher(`/offers/${id}`, {
    method: 'PUT',
    data,
  });
  return offer;
}

export function useOfferUpdate() {
  const queryClient = useQueryClient();
  const toast = useToast();

  return useMutation(updateOffer, {
    onSuccess: async (data: Offer, variables) => {
      queryClient.setQueryData(['offer', { id: variables.id }], data);
      toast({ message: 'Offer updated' });
      await queryClient.invalidateQueries(['offers']);
    },
    onError: () => {
      toast({ isError: true, message: 'Offer not updated' });
    },
  });
}
