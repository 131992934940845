import { useRouter } from 'next/router';
import { Button, DisplayText } from '@shopify/polaris';

import { getUsername } from '@pages/home/helpers';
import { useOfferCreate } from '@pages/offer/hooks';
import { useStores } from '@hooks/useStores/useStores';
import { useStoreCurrency } from '@hooks/useStores';
import { useTotalStatistics } from '@hooks/useTotalStatistics';
import { formatMoney } from '@helpers/formatMoney';
import { formatNumbers } from '@helpers/formatNumbers';
import { HeroSkeleton } from '@components/skeletons';
import { Wrapper, Inner, Content, ImageWrapper } from './styles';

type HomeHeroProps = {
  description: string;
};

export const HomeHero = (props: HomeHeroProps) => {
  const { description } = props;
  const { isLoading } = useOfferCreate();
  const { data: stats, isLoading: statsIsLoading } = useTotalStatistics();
  const { symbol } = useStoreCurrency();
  const { data: stores } = useStores();
  const router = useRouter();

  const username = getUsername(stores?.shop.shop_owner);

  const revenue = stats?.revenue
    ? formatMoney(stats?.revenue, null, symbol)
    : null;
  const views = stats?.views ? formatNumbers(stats?.views, 0) : null;
  const hasStats = revenue !== null && views !== null;

  const statsDescription = (
    <>
      You’ve made <strong>{revenue}</strong> in revenue with over{' '}
      <strong>{views}</strong> page views.
    </>
  );

  if (statsIsLoading) return <HeroSkeleton />;

  return (
    <Wrapper $backgroundColor={hasStats ? '#304751' : '#b0c7c4'}>
      <Inner>
        <Content>
          <div style={{ marginBottom: '2rem' }}>
            <DisplayText element="h2" size="extraLarge">
              <span style={{ color: hasStats ? '#ffffff' : '#202223' }}>
                {`Hi, ${username}`}
              </span>
            </DisplayText>
          </div>
          <DisplayText element="p">
            <span style={{ color: hasStats ? '#ffffff' : '#202223' }}>
              {hasStats ? statsDescription : description}
            </span>
          </DisplayText>
          <div style={{ marginTop: '3.4rem' }}>
            <Button
              size="large"
              loading={isLoading}
              onClick={async () => {
                await router.push(`/templates`);
              }}
            >
              Create offer
            </Button>
          </div>
        </Content>
        <ImageWrapper
          $image={
            hasStats
              ? '/images/hero/hero_image_2.jpg'
              : '/images/hero/hero_image_1.jpg'
          }
        />
      </Inner>
    </Wrapper>
  );
};
