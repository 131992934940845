import { useEffect, useState } from 'react';

import { useAppBridgeRedirect } from '@hooks/useAppBridgeRedirect';
import { useStores } from '@hooks/useStores/useStores';

export function useCheckSubscription() {
  const { data } = useStores();
  const redirect = useAppBridgeRedirect();
  const [status, setStatus] = useState(true);

  useEffect(() => {
    if (data) {
      if (data?.subscription === null) redirect.app('/billing');
      if (data?.subscription !== null) setStatus(false);
    }
  }, [data, redirect]);

  return status;
}
