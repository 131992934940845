import React from 'react';
import { Heading, Layout } from '@shopify/polaris';

import type { Offer } from '@pages/offer/types';

type OfferListProps = {
  title?: string;
  items: Offer[];
  renderItem(item: any): React.ReactNode;
};

export const OfferList = (props: OfferListProps) => {
  const { title, renderItem, items } = props;

  if (!items || !items?.length) return null;

  return (
    <>
      {title && (
        <Layout.Section fullWidth>
          <Heading>{title}</Heading>
        </Layout.Section>
      )}
      {items.map(item => renderItem(item))}
    </>
  );
};
