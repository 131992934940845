export const tips = [
  {
    title: 'About post-purchase offers',
    description: `
      <p>
        Post-purchase offers live directly in the checkout. The offer appears 
        after the order confirmation and before the thank you page.  It is used 
        for:
      </p> 
      <p>
        Gift card offers
        <br />
        Product offers
        <br />
        Gift with purchase
      </p>
    `,
    url: null,
    image: '/images/tips/tip_1.png',
  },
  {
    title: 'Creating a compelling offer',
    description: `
      <p>
        Remember, the customer has already made a purchase, and their time is 
        valuable. To provide the best experience, we recommend you:
      </p>
      <p>
        Create urgency by using a countdown clock
        <br />
        Be clear and concise when writing copy
        <br />
        Make your discount compelling
        <br />
        Show product that compliments their purchase
      </p>
    `,
    url: null,
    image: '/images/tips/tip_2.png',
  },
];
